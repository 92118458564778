"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderDetails = _interopRequireDefault(require("@/views/order/orderDetails.vue"));
var _user = require("@/api/user");
var _bus = _interopRequireDefault(require("@/utils/bus.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    orderDetails: _orderDetails.default
  },
  data: function data() {
    return {
      notificationList: [],
      visible: false,
      total: 0,
      orderId: null,
      loading: false,
      unreadNum: 0
    };
  },
  mounted: function mounted() {
    this.getUnreadTotal();
    // var _this = this;
    // setInterval(() => {
    //   _this.getUnreadTotal();
    // }, 1000 * 60);
    _bus.default.$on("update-message", function () {
      _this.getUnreadTotal();
    });
  },
  methods: _defineProperty(_defineProperty(_defineProperty({
    getUnreadTotal: function getUnreadTotal() {
      var _this2 = this;
      var _this = this;
      (0, _user.getUnreadTotal)().then(function (res) {
        _this2.unreadNum = res;
        setTimeout(function () {
          _this.getUnreadTotal();
        }, 1000 * 60);
      }).catch(function () {
        setTimeout(function () {
          _this.getUnreadTotal();
        }, 1000 * 60);
      });
    },
    onDetails: function onDetails(id) {
      this.visible = false;
      this.orderId = id;
      this.$refs.orderDetail.show(id);
    },
    onUserDetails: function onUserDetails(uid) {
      this.visible = false;
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    }
  }, "onDetails", function onDetails(id) {
    this.visible = false;
    this.orderId = id;
    this.$refs.orderDetail.show(id);
  }), "getMessageList", function getMessageList() {
    var _this3 = this;
    var params = {
      page: 1,
      limit: 10
    };
    this.loading = true;
    (0, _user.getNotifications)(params).then(function (res) {
      _this3.notificationList = res.list;
      _this3.total = res.total;
    }).finally(function () {
      _this3.loading = false;
    });
  }), "handleMore", function handleMore() {
    this.visible = false;
    this.$router.push({
      path: "/user/notice"
    });
  })
};