//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { orderDetailApi, orderCancelApi } from "@/api/order";
import changeDeliverySingle from "./components/changeDeliverySingle";
import orderDone from "./components/orderDone";
import { deepClone } from "@/utils";
export default {
  components: {
    changeDeliverySingle: changeDeliverySingle,
    orderDone: orderDone
  },
  data: function data() {
    return {
      detail: {},
      loading: false,
      orderId: null,
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    handleArchives: function handleArchives(uid) {
      var targetPath = "/user/archives?uid=" + uid;
      var fullPath = this.$route.fullPath;
      if (targetPath == fullPath) {
        return;
      }
      this.dialogVisible = false;
      this.$router.push({
        path: "/user/archives",
        query: {
          uid: uid
        }
      });
    },
    // 订单取消/退款
    handleDelete: function handleDelete() {
      var _this = this;
      this.$prompt("Please enter reason for cancellation", "order cancellation", {
        confirmButtonText: "confirm order cancellation",
        cancelButtonText: "cancel"
      }).then(function (_ref) {
        var value = _ref.value;
        _this.$confirm("confirm cancellation", {
          confirmButtonText: "confirm order cancellation",
          dangerouslyUseHTMLString: true,
          cancelButtonText: "cancel"
        }).then(function () {
          orderCancelApi({
            orderNo: _this.orderId,
            text: value
          }).then(function () {
            _this.dialogVisible = false;
            _this.$message.success("cancel successfully");
            _this.$emit("update");
          });
        });
      }).catch(function () {});
    },
    getSrc: function getSrc(avatar) {
      var subStr = "http";
      if (avatar && avatar.startsWith(subStr)) {
        return avatar;
      } else {
        return "https://img.h2owaterco.com/" + avatar;
      }
    },
    show: function show(orderId) {
      this.dialogVisible = true;
      this.orderId = orderId;
      this.getOrderDetail();
    },
    getTotal: function getTotal(price, quantity) {
      return price * quantity.toFixed(2);
    },
    changeDelivery: function changeDelivery() {
      var detail = deepClone(this.detail);
      this.$refs["changeDeliveryForm"].open(detail);
    },
    completeOrder: function completeOrder() {
      var detail = deepClone(this.detail);
      this.$refs["orderDone"].open(detail);
    },
    handleOrderDone: function handleOrderDone() {
      this.getOrderDetail();
      this.$emit("update");
    },
    getOrderStatus: function getOrderStatus(t) {
      var obj = {
        0: "pending",
        1: "pending",
        2: "on route",
        3: "complete",
        4: "complete",
        8: "canceled",
        9: "exception"
      };
      return obj[t];
    },
    getRate: function getRate(rate) {
      return Number(rate);
    },
    getOrderDetail: function getOrderDetail() {
      var _this2 = this;
      this.loading = true;
      orderDetailApi({
        orderNo: this.orderId
      }).then(function (res) {
        var imgList = res.image ? res.image.split(",") : [];
        var newImgList = imgList.map(function (item) {
          return _this2.getSrc(item);
        });
        var driverImgList = res.driverImg ? res.driverImg.split(",") : [];
        var newDriverImgList = driverImgList.map(function (item) {
          return _this2.getSrc(item);
        });
        var deliveryAmount = 0;
        res.orderInfo.map(function (item) {
          var _item$info = item.info,
            deliveryNum = _item$info.deliveryNum,
            price = _item$info.price,
            payNum = _item$info.payNum;
          if (deliveryNum) {
            deliveryAmount += deliveryNum * Number(price);
          } else {
            deliveryAmount += payNum * Number(price);
          }
        });
        _this2.detail = Object.assign({}, res, {
          imgList: newImgList,
          driverImgList: newDriverImgList,
          deliveryAmount: deliveryAmount
        });
        _this2.loading = false;
      }).catch(function (error) {
        _this2.loading = false;
      });
    }
  }
};