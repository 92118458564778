"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var carApi = _interopRequireWildcard(require("@/api/car.js"));
var driverApi = _interopRequireWildcard(require("@/api/driver.js"));
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var systemMerchantApi = _interopRequireWildcard(require("@/api/merchant.js"));
var _utils = require("@/utils");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultObj = {
  takeNum: null,
  carName: null,
  storeId: null,
  carNum: null,
  sort: 0,
  driverId: null,
  carType: null,
  productList: []
};
var _default = exports.default = {
  // name: "edit"
  props: {},
  data: function data() {
    return {
      constants: this.$constants,
      pram: (0, _utils.deepClone)(defaultObj),
      rules: {
        carName: [{
          required: true,
          message: "vehicle name",
          trigger: ["blur", "change"]
        }],
        carNum: [{
          required: true,
          message: "license plate",
          trigger: ["blur", "change"]
        }],
        storeId: [{
          required: true,
          message: "plant",
          trigger: ["blur", "change"]
        }],
        takeNum: [{
          required: true,
          message: "number of bottles",
          trigger: ["blur", "change"]
        }],
        carType: [{
          required: true,
          message: "type",
          trigger: ["blur", "change"]
        }],
        number: [{
          required: true,
          message: "Please enter number",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      driverData: [],
      dialogVisible: false,
      isCreate: true,
      title: "Add vehicle",
      loading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["storeId"])), {}, {
    totalBottles: function totalBottles() {
      var productList = (0, _utils.deepClone)(this.pram.productList || []);
      var total = 0;
      if (productList.length) {
        productList.map(function (item) {
          total += item.number ? item.number : 0;
        });
      }
      return 0;
    }
  }),
  methods: {
    add: function add() {
      this.title = "Add vehicle";
      this.dialogVisible = true;
      this.isCreate = true;
      this.pram.storeId = this.storeId;
      //   this.pram=Object.assign({},defaultObj,{storeId:this.storeId})
      this.getPlantList();
    },
    edit: function edit(record) {
      this.title = "Edit vehicle";
      this.dialogVisible = true;
      this.isCreate = false;
      this.getPlantList(record);
    },
    getPlantList: function getPlantList(record) {
      var _this = this;
      this.loading = true;
      systemMerchantApi.merchantList(this.listPram).then(function (data) {
        _this.listData = data.list;
        if (record) {
          _this.pram = Object.assign({}, record);
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    close: function close() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handlerSubmit: (0, _validate.Debounce)(function (form) {
      var _this2 = this;
      if (this.storeId) {
        this.pram.storeId = this.storeId;
      }
      if (this.totalBottles > this.pram.takeNum) {
        this.$message.warning("Over tack num");
        return;
      }
      this.$refs.form.validate(function (valid) {
        if (!valid) return;
        if (_this2.isCreate) {
          _this2.handlerSave();
        } else {
          _this2.handlerEdit();
        }
      });
    }),
    handlerSave: function handlerSave() {
      var _this3 = this;
      var obj = {};
      var filterArray = ["productList"];
      for (var key in this.pram) {
        console.log(this.pram);
        if (filterArray.indexOf(key) < 0) {
          obj[key] = this.pram[key];
        }
      }
      carApi.addCar(obj).then(function (data) {
        _this3.$message.success("successful");
        _this3.close();
        _this3.$emit("hideEditDialog");
      });
    },
    handlerEdit: function handlerEdit() {
      var _this4 = this;
      var obj = {};
      var filterArray = ["productList"];
      for (var key in this.pram) {
        if (filterArray.indexOf(key) < 0) {
          obj[key] = this.pram[key];
        }
      }
      carApi.updateCar(obj).then(function (data) {
        _this4.dialogVisible = false;
        _this4.close();
        _this4.$emit("hideEditDialog");
      });
    }
  }
};