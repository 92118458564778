"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    total: Number,
    pagerCount: {
      type: Number,
      validator: function validator(value) {
        return (value | 0) === value && value > 4 && value < 22 && value % 2 === 1;
      },
      default: 5
    },
    currentPage: {
      type: Number,
      default: 1
    },
    layout: {
      default: "total, sizes,prev, pager, next, jumper"
    },
    pageSizes: {
      default: [10, 20, 30, 40]
    },
    prevText: String,
    nextText: String
  },
  computed: {
    maxPageCount: function maxPageCount() {
      var maxPageCount = Math.ceil(this.total / this.pageSize);
      return maxPageCount;
    },
    isShowPagination: function isShowPagination() {
      return true;
    },
    firstFlag: function firstFlag() {
      return this.layout.indexOf("first") > -1;
    },
    endFlag: function endFlag() {
      return this.layout.indexOf("end") > -1;
    },
    paginationLayout: function paginationLayout() {
      var layoutArray = this.layout.split(",");
      var newLayout = [];
      layoutArray.filter(function (item) {
        if (item === "first" || item === "end") {
          return;
        } else {
          newLayout.push(item);
        }
      });
      return newLayout.join(",");
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(e) {
      this.$emit("size-change", e);
    },
    handlePageChange: function handlePageChange(e) {
      this.$emit("current-change", e);
    },
    handleFirstChange: function handleFirstChange(e) {
      if (this.currentPage !== 1 && this.maxPageCount !== 0 && this.maxPageCount !== 1) {
        this.handlePageChange(e);
      }
    },
    handleEndChange: function handleEndChange(e) {
      if (this.currentPage !== this.maxPageCount && this.maxPageCount !== 0 && this.maxPageCount !== 1) {
        this.handlePageChange(e);
      }
    }
  }
};