var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        title: "Order detail",
        visible: _vm.dialogVisible,
        width: "1200px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _vm.detail.id
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-center justify-between",
                        staticStyle: { height: "70px" },
                      },
                      [
                        _c("span", { staticClass: "flex top" }, [
                          _vm._v(
                            "Order " +
                              _vm._s(_vm.detail.sort) +
                              " :\n            "
                          ),
                          _c("h3", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getOrderStatus(_vm.detail.status)) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.detail.status !== 4
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.changeDelivery },
                                  },
                                  [_vm._v("Change delivery")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.status !== 4
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.completeOrder },
                                  },
                                  [_vm._v("Complete order")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.status !== 4
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleDelete },
                                  },
                                  [_vm._v("cancel order")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.detail.status == 4
                      ? _c(
                          "el-card",
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                  },
                                  [_vm._v("DELIVERED NOTES")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form",
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "return bottles:" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.detail.receiveNum) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "return bottles(fee):",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("fmtMoney")(
                                                  _vm.detail.freightRefundPrice,
                                                  2
                                                )
                                              ) + "\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-card", [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "primary", underline: false } },
                            [_vm._v("USER INFORMATION")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "infoCenter" }, [
                        _c("img", {
                          staticStyle: {
                            width: "58px",
                            height: "58px",
                            "border-radius": "50%",
                          },
                          attrs: { src: require("@/assets/imgs/user.svg") },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex align-center" }, [
                          _c("div", { staticClass: "info-box" }, [
                            _c(
                              "span",
                              { staticClass: "flex text" },
                              [
                                _c("p", [_vm._v("username:")]),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "label",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleArchives(
                                          _vm.detail.uid
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.detail.nikeName))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex text" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "flex",
                                  staticStyle: { "margin-right": "40px" },
                                },
                                [
                                  _c("p", [_vm._v("Trading account:")]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(_vm.detail.verifyCode || "--")
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "flex" }, [
                                _c("p", [_vm._v("reserve phone number:")]),
                                _vm._v(" "),
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(_vm._s(_vm.detail.userPhone)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c(
                              "el-link",
                              { attrs: { type: "primary", underline: false } },
                              [_vm._v("ORDER DETAILS")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "infoCenter" },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "58px",
                                height: "58px",
                                "border-radius": "50%",
                              },
                              attrs: {
                                src: _vm.detail.systemStore
                                  ? _vm.getSrc(_vm.detail.systemStore.logo)
                                  : "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex align-center" }, [
                              _c(
                                "div",
                                { staticClass: "info-box" },
                                [
                                  _c("span", { staticClass: "flex" }, [
                                    _c("p", { staticClass: "label" }, [
                                      _vm._v(
                                        _vm._s(_vm.detail.systemStore.name)
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-rate", {
                                    attrs: {
                                      value: _vm.getRate(
                                        _vm.detail.systemStore.scoreAvg
                                      ),
                                      disabled: "",
                                      "show-score": "",
                                      "text-color": "#ff9900",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "20px",
                            },
                            attrs: {
                              data: _vm.detail.orderInfo,
                              "header-cell-style": {
                                fontWeight: "bold",
                                background: "#E8F4FF",
                                color: "#515a6e",
                                height: "40px",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "Goods" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "flex align-center" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview mr10",
                                              },
                                              [
                                                _c("el-image", {
                                                  attrs: {
                                                    src: _vm.getSrc(
                                                      scope.row.info.image
                                                    ),
                                                    "preview-src-list": [
                                                      _vm.getSrc(
                                                        scope.row.info.image
                                                      ),
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-center",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.info.productName
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1976682425
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "quantity", width: "140" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.info.payNum) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4200388451
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "delivery num", width: "140" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.info.deliveryNum || 0) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2759680293
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "unit price", width: "140" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.info.price) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                280437936
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "total price", width: "140" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("fmtMoney")(
                                                _vm.getTotal(
                                                  scope.row.info.price,
                                                  scope.row.info.payNum
                                                ),
                                                2
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                968317823
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex total",
                            staticStyle: {
                              "justify-content": "flex-end",
                              "font-weight": "normal",
                            },
                          },
                          [
                            _vm._v(
                              "\n            PRO TOTAL PRICE : $" +
                                _vm._s(
                                  _vm._f("fmtMoney")(
                                    _vm.detail.deliveryAmount,
                                    2
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex total",
                            staticStyle: {
                              "justify-content": "flex-end",
                              "font-weight": "normal",
                            },
                          },
                          [
                            _vm._v(
                              "\n            DEPOSIT : $" +
                                _vm._s(_vm.detail.freightPrice) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex total",
                            staticStyle: {
                              "justify-content": "flex-end",
                              "font-weight": "normal",
                            },
                          },
                          [
                            _vm._v(
                              "\n            FREIGHT CHARGE: $" +
                                _vm._s(_vm.detail.payPostage) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex total",
                            staticStyle: { "justify-content": "flex-end" },
                          },
                          [
                            _vm._v(
                              "\n            TOTAL COST : $\n            "
                            ),
                            _c("p", [_vm._v(_vm._s(_vm.detail.payPrice))]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-divider"),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            staticStyle: { "margin-left": "30px" },
                            attrs: {
                              "label-width": "200px",
                              "label-position": "left",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Delivery information: " } },
                              [_vm._v(_vm._s(_vm.detail.userAddress))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Order number: " } },
                              [_vm._v(_vm._s(_vm.detail.orderId))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Delivery date: " } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTime")(
                                      _vm.detail.deliveryDate,
                                      "YY-MM-DD"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Payment method: " } },
                              [_vm._v(_vm._s(_vm.detail.payTypeStr))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.detail.status > 1
                      ? _c(
                          "el-card",
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                  },
                                  [_vm._v("DELIVERY PERSON DETAILS")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "infoCenter" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "58px",
                                  height: "58px",
                                  "border-radius": "50%",
                                },
                                attrs: {
                                  src: require("@/assets/imgs/driver.webp"),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex align-center" }, [
                                _c("div", { staticClass: "info-box" }, [
                                  _c("span", { staticClass: "flex" }, [
                                    _c("p", { staticClass: "label" }, [
                                      _vm._v(_vm._s(_vm.detail.deliveryName)),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.detail.status == 4
                              ? _c(
                                  "el-form",
                                  { staticStyle: { "margin-left": "82px" } },
                                  [
                                    _c("el-form-item", {
                                      attrs: { label: "Delivery confirmation" },
                                    }),
                                    _vm._v(" "),
                                    _vm.detail.image
                                      ? _c(
                                          "el-form-item",
                                          { attrs: { label: "" } },
                                          _vm._l(
                                            _vm.detail.imgList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "demo-image__preview",
                                                  staticStyle: {
                                                    width: "55px",
                                                    height: "55px",
                                                    display: "inline-block",
                                                    "margin-top": "15px",
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      height: "100%",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      "preview-src-list":
                                                        _vm.detail.imgList,
                                                      fit: "cover",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.detail.driverImg || _vm.detail.driverRemark
                              ? _c(
                                  "el-form",
                                  { staticStyle: { "margin-left": "82px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Driver's Notes:" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detail.driverRemark) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.detail.driverImg
                                      ? _c(
                                          "el-form-item",
                                          { attrs: { label: "" } },
                                          _vm._l(
                                            _vm.detail.driverImgList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "demo-image__preview",
                                                  staticStyle: {
                                                    width: "55px",
                                                    height: "55px",
                                                    display: "inline-block",
                                                    "margin-top": "15px",
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      height: "100%",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      "preview-src-list":
                                                        _vm.detail
                                                          .driverImgList,
                                                      fit: "cover",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("change-delivery-single", {
            ref: "changeDeliveryForm",
            on: { update: _vm.handleOrderDone },
          }),
          _vm._v(" "),
          _c("order-done", {
            ref: "orderDone",
            on: { update: _vm.handleOrderDone },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }