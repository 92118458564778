"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _driver = require("@/api/driver");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//

// 权限判断函数
var loader = new _jsApiLoader.Loader({
  apiKey: "AIzaSyB0KvRZORCJ-XVdwPgqvfYpl70ahSD8KJ8",
  //api的key
  version: "weekly",
  //版本
  libraries: ["places", "drawing"] //插件库
});
var geocoder;
var map;
var marker;
var infowindow;
var service;
var _default = exports.default = {
  components: {},
  name: "Dashboard",
  data: function data() {
    return {
      authStatus: null,
      orderId: null,
      authHost: "",
      detail: {},
      loading: true,
      authQueryStatus: false,
      driverList: []
    };
  },
  mounted: function mounted() {
    window.show = this.show;
  },
  created: function created() {
    this.getLocList();
  },
  methods: {
    getLocList: function getLocList() {
      var _this = this;
      (0, _driver.driverLocList)().then(function (data) {
        _this.driverList = data || [];
        _this.initMap();
      }).catch(function () {
        _this.initMap();
      });
    },
    checkPermi: _permission.checkPermi,
    initMap: function initMap() {
      var _this2 = this;
      var that = this;
      var mapOptions = {
        center: {
          lat: 22.602,
          lng: 114.043
        },
        zoom: 13,
        streetViewControl: false
      };
      loader.load().then(function (google) {
        var MarkerWithLabel = require("markerwithlabel")(google.maps);
        _this2.google = google;
        map = new google.maps.Map(document.getElementById("map"), mapOptions);
        _this2.googleMap = map;
        _this2.google.maps.event.addListener(map, "click", function (event) {
          console.log(event.latLng.lat());
          console.log(event.latLng.lng());
        });
        geocoder = new google.maps.Geocoder();
        // service 地点查询类
        service = new google.maps.places.PlacesService(map);
        infowindow = new google.maps.InfoWindow({
          // 地图信息窗口
          content: ""
        });
        var driverList = _this2.driverList;
        if (driverList.length > 0) {
          var noData = [null, undefined, ""];
          //未获取到
          if (noData.includes(driverList[0].latitude) || noData.includes(driverList[0].longitude)) {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(function (position) {
                var pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                map.setCenter(pos);
              }, function () {
                console.log("未授权地理位置");
                // that.handleLocationError(true, infowindow, map.getCenter());
              });
            } else {
              that.handleLocationError(false, infowindow, map.getCenter());
            }
          } else {
            var pos = {
              lat: Number(driverList[0].latitude),
              lng: Number(driverList[0].longitude)
            };
            map.setCenter(pos);
          }
          //已配送
          var markerSvg = window.btoa("\n                <svg width=\"39\" height=\"48\" viewBox=\"0 0 39 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path d=\"M32.2972 6.50512L32.3033 6.51144L32.3095 6.51765L32.4293 6.63742C35.6615 9.99257 37.7008 14.6693 37.7008 19.8316C37.7008 25.1234 35.6591 29.7993 32.4293 33.152L32.3095 33.2718L32.303 33.2783L32.2966 33.2849L19.504 46.5558L6.70805 33.1618L6.7001 33.1535L6.69197 33.1454L6.57213 33.0256C3.34003 29.6705 1.30078 24.9937 1.30078 19.8315C1.30078 14.666 3.34275 9.9866 6.5785 6.63099L6.68468 6.52088L6.75308 6.38407L6.80593 6.27838C10.1554 2.98227 14.5368 1 19.5008 1C24.5191 1 28.9376 3.02557 32.2972 6.50512ZM11.806 18.5685C11.806 22.8738 15.2497 26.5158 19.5008 26.5158C23.7519 26.5158 27.1955 22.8738 27.1955 18.5684C27.1955 14.263 23.7519 10.6211 19.5008 10.6211C16.9861 10.6211 14.8187 11.9508 13.4045 13.7882C12.3831 15.1024 11.806 16.7077 11.806 18.5685Z\" fill=\"#F44C35\" stroke=\"white\" stroke-width=\"2\"/>\n                </svg>");
          var infowindow;
          driverList.forEach(function (item, i) {
            var marker = new MarkerWithLabel({
              position: {
                lat: Number(item.latitude),
                lng: Number(item.longitude)
              },
              icon: {
                url: "data:image/svg+xml;base64,".concat(markerSvg),
                //配送完成/未完成图标
                scaledSize: new google.maps.Size(100, 40),
                fillColor: "red"
              },
              draggable: false,
              map: map,
              labelContent: "".concat(item.firstName, " ").concat(item.lastName),
              labelAnchor: new google.maps.Point(50, 0),
              labelClass: "labels",
              labelStyle: {
                padding: "5px 5px",
                textAlign: "center",
                backgroundColor: "#fff",
                width: "100px",
                color: "#0072E2",
                fontSize: "14px",
                border: "1px solid #0072E2",
                borderRadius: "4px",
                borderColor: "#0072E2"
              }
            });
          });
        }
      }).catch(function (e) {});
      this.loading = false;
    },
    handleLocationError: function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation ? "Error: The Geolocation service failed." : "Error: Your browser doesn't support geolocation.");
      infoWindow.open(map);
    }
  }
};