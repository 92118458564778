function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as carApi from "@/api/car.js";
import * as driverApi from "@/api/driver.js";
import { mapGetters } from "vuex";
import { Debounce } from "@/utils/validate";
import * as systemMerchantApi from "@/api/merchant.js";
import productManage from "./productManage.vue";
import { deepClone } from "@/utils";
import moment from "moment";
import { driverList } from "@/api/driver";
import { driverToCar } from "@/api/order";
export default {
  // name: "edit"
  components: {
    productManage: productManage
  },
  props: {
    editData: {
      type: Object,
      default: function _default() {
        return {
          rules: []
        };
      }
    },
    deliveryDate: {
      type: [String, Date],
      default: ""
    }
  },
  data: function data() {
    return {
      active: 0,
      constants: this.$constants,
      visible: false,
      pram: {
        productList: []
      },
      driverList: [],
      rules: {
        carName: [{
          required: true,
          message: "vehicle name",
          trigger: ["blur", "change"]
        }],
        driverId: [{
          required: true,
          message: "Please select",
          trigger: ["blur"]
        }],
        carNum: [{
          required: true,
          message: "license plate",
          trigger: ["blur", "change"]
        }],
        storeId: [{
          required: true,
          message: "plant",
          trigger: ["blur", "change"]
        }],
        carType: [{
          required: true,
          message: "type",
          trigger: ["blur", "change"]
        }],
        number: [{
          required: true,
          message: "Please enter number",
          trigger: ["blur", "change"]
        }]
        // driverId:[
        //     { required: true, message: "Please select a driver", trigger: ["blur", "change"] }
        // ],
      },
      listData: [],
      driverData: [],
      loading: false,
      minLoad: 0,
      isEditable: true,
      title: "Loading vehicle"
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["storeId"])), {}, {
    totalBottles: function totalBottles() {
      var productList = deepClone(this.pram.productList || []);
      var total = 0;
      if (productList.length) {
        productList.map(function (item) {
          total += item.number ? item.number : 0;
        });
      }
      return total;
    }
  }),
  methods: {
    open: function open(params, minLoad) {
      var _this2 = this;
      this.active = 0;
      this.title = "Loading vehicle";
      this.isEditable = true;
      this.visible = true;
      this.loading = true;
      this.minLoad = minLoad;
      this.getDriverList();
      this.pram = Object.assign({}, params, {
        driverId: null,
        id: params.carId
      });
      this.loading = false;
      return;
      carApi.carInfo({
        id: params.carId,
        deliveryDate: moment(new Date(this.deliveryDate)).format("YYYY-MM-DD")
      }).then(function (data) {
        _this2.pram = Object.assign({}, data, {
          carId: data.id ? data.id : params.carId,
          productList: data.productList ? data.productList : [],
          orderList: params.orderList,
          driverId: null,
          allNum: params.allNum,
          takeNum: data.takeNum ? data.takeNum : params.takeNum
        });
      }).finally(function () {
        _this2.loading = false;
      });
    },
    getDriverList: function getDriverList() {
      var _this3 = this;
      driverList({
        page: 1,
        limit: 99999,
        deliveryDate: moment(new Date(this.deliveryDate)).format("YYYY-MM-DD")
      }).then(function (res) {
        _this3.driverList = res.list;
      });
    },
    handleAdd: function handleAdd() {
      //打开商品表-添加数据
      this.$refs.productManage.edit();
    },
    handleOk: function handleOk(value) {
      var productList = deepClone(this.pram.productList) || [];
      var ids = productList.map(function (item) {
        return item.productId;
      });
      var valueList = deepClone(value);
      var selectArray = valueList.filter(function (item) {
        return ids.indexOf(item.productId) < 0;
      });
      var newList = productList.concat(selectArray).map(function (item) {
        if (ids.indexOf(item.productId) > -1) {
          item.number = productList[ids.indexOf(item.productId)].number || 0;
        } else {
          item.number = 0;
        }
        return item;
      });
      this.pram = Object.assign({}, this.pram, {
        productList: _toConsumableArray(newList)
      });
    },
    handleDel: function handleDel(index) {
      var productList = deepClone(this.pram.productList || []);
      productList.splice(index, 1);
      this.$set(this.pram, "productList", productList);
    },
    close: function close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    handlePre: function handlePre() {
      this.active = this.active - 1;
    },
    handleNext: function handleNext() {
      var _this4 = this;
      this.$refs.form.validate(function (valid) {
        if (!valid) return;
        _this4.active = _this4.active + 1;
      });
    },
    handlerSubmit: Debounce(function (form) {
      var _this5 = this;
      if (this.storeId) {
        this.pram.storeId = this.storeId;
      }
      var productList = deepClone(this.pram.productList || []);
      if (productList.length == 0) {
        this.$message.warning("Please choose product");
        return;
      }
      if (this.totalBottles < this.minLoad) {
        this.$message.warning("Load at least ".concat(this.minLoad, " barrels"));
        return;
      }
      if (this.totalBottles > this.pram.carTakeNum) {
        this.$message.warning("Over tack num");
        return;
      }
      this.$refs.form.validate(function (valid) {
        if (!valid) return;
        _this5.handlerEdit();
      });
    }),
    handlerEdit: function handlerEdit() {
      var _this6 = this;
      var _this = this;
      this.loading = true;
      var data = {
        carId: _this.pram.carId,
        // "deliveryTime": this.form.deliveryTime,
        driverId: _this.pram.driverId,
        // "num":this.form.num,
        orderList: _this.pram.orderList,
        deliveryDate: moment(new Date(this.deliveryDate)).format("YYYY-MM-DD")
      };
      data.orderIds = _this.pram.orderList.map(function (e) {
        return e.id;
      });
      driverToCar(data).then(function () {
        var obj = {};
        var filterArray = ["productList"];
        for (var key in _this.pram) {
          if (filterArray.indexOf(key) < 0) {
            obj[key] = _this.pram[key];
          }
        }
        var productList = deepClone(_this.pram.productList || []);
        var newList = productList.map(function (item) {
          return item.productId + "-" + item.number;
        });
        obj["takeInfo"] = newList.join(",");
        obj["deliveryDate"] = moment(new Date(_this.deliveryDate)).format("YYYY-MM-DD");
        carApi.updateCar(obj).then(function (data) {
          _this.$message.success("update successfully");
          _this.$emit("update");
          _this.loading = false;
          _this.close();
        }).catch(function () {
          _this.loading = false;
        });
      }).catch(function () {
        _this.loading = false;
      });
      return;
      var changeDriverFunc = new Promise(function (resolve, reject) {
        var data = {
          carId: _this.pram.carId,
          // "deliveryTime": this.form.deliveryTime,
          driverId: _this.pram.driverId,
          // "num":this.form.num,
          orderList: _this.pram.orderList,
          deliveryDate: moment(new Date(_this6.deliveryDate)).format("YYYY-MM-DD")
        };
        data.orderIds = _this.pram.orderList.map(function (e) {
          return e.id;
        });
        driverToCar(data).then(function (async) {
          resolve();
        }).catch(function () {
          reject();
        });
      });
      var changeProductFunc = new Promise(function (resolve, reject) {
        var obj = {};
        var filterArray = ["productList"];
        for (var key in _this6.pram) {
          if (filterArray.indexOf(key) < 0) {
            obj[key] = _this6.pram[key];
          }
        }
        var productList = deepClone(_this6.pram.productList || []);
        var newList = productList.map(function (item) {
          return item.productId + "-" + item.number;
        });
        obj["takeInfo"] = newList.join(",");
        obj["deliveryDate"] = moment(new Date(_this6.deliveryDate)).format("YYYY-MM-DD");
        carApi.updateCar(obj).then(function (data) {
          _this.$message.success("update successfully");
          _this.$emit("update");
          _this.loading = false;
          _this.close();
        }).catch(function () {
          _this.loading = false;
        });
      });
      Promise.all([changeDriverFunc, changeProductFunc]).then(function () {
        // results是一个长度为2的数组，放置着p1、p2的resolve
        _this6.$message.success("update successfully");
        _this6.$emit("update");
        _this6.loading = false;
        _this6.close();
      }).finally(function () {
        _this6.loading = false;
      });
    }
  }
};