var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container mt-1" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.tableFrom,
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Product search：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              staticStyle: { width: "260px" },
                              attrs: {
                                placeholder:
                                  "Please enter product name, keywords or item ID",
                                size: "small",
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.seachList($event)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keywords", $$v)
                                },
                                expression: "tableFrom.keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:product:list"],
                                    expression: "['admin:product:list']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: { click: _vm.seachList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.storeId
                ? _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("Add product")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.storeId
                ? _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addShopShow },
                    },
                    [_vm._v("Add mall products")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.storeId
                ? _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [_vm._v("Add to Cart")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                border: "",
                "highlight-current-row": true,
                "header-cell-style": {
                  fontWeight: "bold",
                  background: "#f8f8f9",
                  color: "#515a6e",
                  height: "40px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Product photo", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.productImg,
                                "preview-src-list": [scope.row.productImg],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.storeId
                ? _c("el-table-column", {
                    attrs: {
                      label: "Inventory",
                      "min-width": "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.stock || 0)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      448092886
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Product name",
                  prop: "productName",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "Price",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sales",
                  label: "Sales Volume",
                  "min-width": "100",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "status", "min-width": "90", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            "active-value": true,
                            "inactive-value": false,
                            "active-text": "Active",
                            "inactive-text": "Inactive",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.isShow,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isShow", $$v)
                            },
                            expression: "scope.row.isShow",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "operation",
                  "min-width": "160",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.storeId
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRecord(scope)
                                  },
                                },
                              },
                              [_vm._v("History of Inventory")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !scope.row.type
                          ? [
                              !_vm.storeId
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["admin:product:update"],
                                          expression:
                                            "['admin:product:update']",
                                        },
                                      ],
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("edit")]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              !_vm.storeId
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["admin:product:update"],
                                          expression:
                                            "['admin:product:update']",
                                        },
                                      ],
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editShopShow(
                                            scope.row.productId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("edit")]
                                  )
                                : _vm._e(),
                            ],
                        _vm._v(" "),
                        !_vm.storeId
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["admin:product:delete"],
                                    expression: "['admin:product:delete']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.productId,
                                      _vm.tableFrom.type
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s("delete"))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.deliveryVisible,
            title: "History of Inventory",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "720px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deliveryVisible = $event
            },
          },
        },
        [
          _vm.deliveryVisible
            ? _c("inventory", { attrs: { productId: _vm.productId } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Add to Cart",
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: "",
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "product in storage:",
                            prop: "productId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "please choose product" },
                              model: {
                                value: _vm.form.productId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "productId", $$v)
                                },
                                expression: "form.productId",
                              },
                            },
                            _vm._l(_vm.shopList, function (item) {
                              return _c("el-option", {
                                key: item.productId,
                                attrs: {
                                  label: item.productName,
                                  value: item.productId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Quantity in storage:",
                            prop: "number",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "please enter  Quantity ",
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { width: "100%", "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerSubmit("form")
                        },
                      },
                    },
                    [_vm._v("confirm")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.close("form")
                        },
                      },
                    },
                    [_vm._v("cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("productEdit", { ref: "modalForm" }),
      _vm._v(" "),
      _c("add-shop", { ref: "addShopForm", on: { update: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }