var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-page",
      attrs: {
        visible: _vm.visible,
        title: "新增商城商品",
        "before-close": _vm.handleClose,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "storeName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "249", placeholder: "商品名称" },
                        model: {
                          value: _vm.form.storeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "storeName", $$v)
                          },
                          expression: "form.storeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "友情提示", prop: "friendlyReminder" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          placeholder: "请输入友情提示",
                        },
                        model: {
                          value: _vm.form.friendlyReminder,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "friendlyReminder", $$v)
                          },
                          expression: "form.friendlyReminder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品保障:", prop: "productSafeguard" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "250",
                          rows: 3,
                          placeholder: "商品保障",
                        },
                        model: {
                          value: _vm.form.productSafeguard,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "productSafeguard", $$v)
                          },
                          expression: "form.productSafeguard",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "积分：", prop: "giveIntegral" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "giveIntegral" },
                        model: {
                          value: _vm.form.giveIntegral,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "giveIntegral", $$v)
                          },
                          expression: "form.giveIntegral",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格：", prop: "price" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "price" },
                        model: {
                          value: _vm.form.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price", $$v)
                          },
                          expression: "form.price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                _vm._b({}, "el-col", _vm.grid2, false),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "sort" },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示大图", prop: "flatPattern" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1")
                            },
                          },
                        },
                        [
                          _vm.form.flatPattern
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.form.flatPattern },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图", prop: "sliderImage" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("9")
                            },
                          },
                        },
                        [
                          _vm.form.sliderImage.length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "grid",
                                    "grid-template-columns":
                                      "1fr 1fr 1fr 1fr 1fr",
                                  },
                                },
                                _vm._l(
                                  _vm.form.sliderImage,
                                  function (item, imgIndex) {
                                    return _c(
                                      "div",
                                      { key: imgIndex, staticClass: "pictrue" },
                                      [_c("img", { attrs: { src: item } })]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "upLoad", attrs: { else: "" } },
                            [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否热卖", prop: "isHot" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.isHot,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isHot", $$v)
                            },
                            expression: "form.isHot",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("是"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("礼包详情")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.form.giftBagInfo, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          width: "200",
                          label: "商品名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      prop:
                                        "giftBagInfo." + scope.$index + ".name",
                                      "label-width": "0",
                                      rules: _vm.rules.name,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入商品名称" },
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "value", width: "200", label: "数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      prop:
                                        "giftBagInfo." +
                                        scope.$index +
                                        ".value",
                                      "label-width": "0",
                                      rules: _vm.rules.value,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入数量",
                                      },
                                      model: {
                                        value: scope.row.value,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "value", $$v)
                                        },
                                        expression: "scope.row.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "operation" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.form.giftBagInfo.length - 1 == scope.$index
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus-outline add",
                                      staticStyle: {
                                        "font-size": "30px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.update()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.form.giftBagInfo.length > 1
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-remove-outline remove",
                                      staticStyle: {
                                        "font-size": "30px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.update(scope.$index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }